import axios from 'axios'
import endpoint from '@utils/endpoints'

export default async function checkSubs(service_id, num) {
  try {
    const url = endpoint('check', {
      num,
      offer_id: service_id
    });
    const { data } = await axios.get(url);

    return data;

  } catch (error) {
    throw new Error(error);
  }
}