import axios from 'axios';
import isDev from '@utils/is-dev'

export async function getHeaderEnrichment() {
  let { data } = await axios.get('http://apps.mobivs.com/HE/index.php');
  await new Promise((resolve) => setTimeout(resolve('send number'), 1000));

  if(isDev()) {
    return false;
  }
  return data?.MSISDN || false;
}