import axios from 'axios';

import { WP_REST } from '../constants';


export async function fetchKeyword(slug) {
  axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
  try {
    const response = await axios.get(`${WP_REST}indico/v1/category`, {
      params: {
        slug
      }
    });

    return response.data;

  } catch (error) {
    console.log('error...>', error)
    throw new Error(error);
  }
}

export async function fetchKeywordByService(service_id) {
  axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
  try {
    const response = await axios.get(`${WP_REST}indico/v1/category`, {
      params: {
        service_id
      }
    });

    return response.data;

  } catch (error) {
    console.log('error...>', error)
    throw new Error(error);
  }
}

export async function fetchKeywords(service) {
  axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

  try {
    const response = await axios.get(`${WP_REST}indico/v1/categories`, {
      params: {
          parent: service
      }
    });

    return response.data;

  } catch (error) {
    throw new Error(error);
  }
}

export async function fetchContent(slug) {
  try {
    const response = await axios.get(`${WP_REST}indico/v1/content`, {
      params: {
        category: slug
      }
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {

  }
}
