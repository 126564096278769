import { createContext, useState, useContext
    // useMemo, useEffect
} from 'react'
import { getSubdomains } from '@utils/subdomains';

import { SERVICES } from '../constants';

export const AppContext = createContext(null);
const [network, service] = getSubdomains();
const InitialState = {
    user: {msisdn: ''},
    network,
    serviceName: SERVICES[service]
};

export const AppContextProvider = ({children}) => {
    const [store, setStore] = useState(InitialState);
    const [isCheckSub, setIsCheckSub] = useState('stale'); // 'stale' | 'refetch'

    const setSubscriptions = (subs) => setStore((state) => ({
        ...state,
        subs: [...subs]
    }))
    // const [hydrated, setHydrate] = useState(false);

    // const value = useMemo(() => ({store, setStore}), [store, setStore]);

    // useEffect(() => {
    //     // localStorage.clear();
    //     // const localStore = window.localStorage.getItem('indicoContent');

    //     setStore({
    //         ...InitialState,
    //         // ...JSON.parse(localStore)
    //     });
    //     // setHydrate(true);
    // }, [])

    // useEffect(() => {
    //     console.log('hydrated: ', value.store);
    // }, [hydrated]) // eslint-disable-line

    // useEffect(() => {
    //     window.localStorage.setItem('myGhVasContent', JSON.stringify(store));
    // }, [store]);

    return <AppContext.Provider value={{store, isCheckSub, setStore, setSubscriptions, setIsCheckSub}}>
        {children}
    </AppContext.Provider>
}


export const useStore = () => useContext(AppContext).store;
export const useSetStore = () => useContext(AppContext).setStore;
export const useCheckSub = () => {
    const { isCheckSub, setIsCheckSub } = useContext(AppContext);

    return { isCheckSub, setIsCheckSub };
}
