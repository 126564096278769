import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './containers/App/App';
import SEO from './SEO';

// 👇️ make sure to use the correct root element ID
// from your public/index.html file
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <SEO>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SEO>
  </StrictMode>,
);