import { useState, createContext, useContext } from 'react'
import Classnames from 'classnames'
import { useStore } from '@context/store'
import Unsubscribe from '@components/Unsubscribe'
import { ReactComponent as MenuIcon } from '@assets/img/icons/burger-menu.svg'
import { useQuery } from '@tanstack/react-query'
import { fetchKeyword } from '../services/wpApi'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

const MenuContext = createContext(null);
export const useMenu = () => useContext(MenuContext);

const MenuWrapper = () => {
  const { slug } = useParams();
  const { data: keyword } = useQuery(['keyword', slug], () => fetchKeyword(slug)); 
  const { open, toggleMenu, feedback } = useMenu()
  const {
    user: {
      msisdn
    },
    serviceName
  } = useStore();


  return (
    <>
    <button onClick={toggleMenu}>
      <MenuIcon />
    </button>

      <div className={Classnames(
        {
          "fixed z-90 top-0 left-0 flex flex-col items-center bg-white duration-500": true,
          "w-screen h-screen opacity-100": open,
          "w-0 h-0 opacity-0 -left-full" : !open
        }
      )}>
        <div className="item-start py-16">
          <button className='border-b border-black pb-4 px-6' onClick={toggleMenu}>
            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.26831 20.2042L19.7317 1.74088" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M1.26831 1.79651L19.7317 20.2599" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>

        <div className="">
          <div className="mb-6">
            <span>phone number</span>
            <p className='text-[26px] font-regular'>+{msisdn}</p>
          </div>

          <div className="mb-6">
            <span>status</span>
            <div className="flex">
              <p className='text-[26px] font-regular'>Active</p>
              <Unsubscribe {...{category: serviceName, msisdn, subscription_id: keyword?.service_id}} />
            </div>
          </div>

          {feedback.message !== "" && <div className={classNames({
            'text-red-500': feedback.type === 'error',
            'text-green-500': feedback.type === 'success'
          })}>
            {feedback.message}
          </div>}
        </div>
      </div>
    </>
  )
}


function Menu() {
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState({type: '', message: ''});

  const values = {
    open,
    feedback,
    toggleMenu: () => setOpen(!open),
    setFeedback,
  }
  return (
    <MenuContext.Provider value={values}><MenuWrapper /></MenuContext.Provider>
  )
}
export default Menu