import React, { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { AppContext } from '@context/store'

import { getHeaderEnrichment } from '@services/headerEnrichment';
// import { fetchKeywords } from '@services/wpApi';
import Navbar from '@components/Navbar'
// import Infotainment from './Infotainment';
// import Indico from './Indico';

const Base = () => {
  const { setStore, store: {
    serviceName
  }} = useContext(AppContext);
  const { isLoading, data } = useQuery(['header-enrichment'], getHeaderEnrichment);
  // const queryClient = useQueryClient();

  useEffect(() => {
    if (!isLoading) {
      setStore(prev => ({
        ...prev,
        user: {
          ...prev.user,
          msisdn: data
        }
      }));
    }
    // queryClient.prefetchQuery(['keywords', serviceName], () => fetchKeywords(serviceName));
  }, [data, isLoading]); // eslint-disable-line

  if(! serviceName) return <>
    we couldn't identify your service
  </>;

  return (
    <>
      <Navbar />
      <div className="pt-6">
        <Outlet />
      </div>
    </>
  )
}

export default Base
