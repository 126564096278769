import { useState } from 'react';
import { Stream } from '@cloudflare/stream-react'
import {ReactComponent as PlayIcon} from '@assets/img/icons/play.svg';
import {ReactComponent as ArrowRightIcon} from '@assets/img/icons/arrow-right.svg';
import {ReactComponent as PictureIcon} from '@assets/img/icons/picture.svg';
import classNames from 'classnames';

const Image = ({title, featured_media: {media}}) => {
  return (
    <img src={media} className="rounded-2xl w-full" alt={title} />
  )
}

const Video = ({featured_media, onPlay, onEnded}) => {
  const media = JSON.parse(featured_media.media);

    // console.log(media);
    return (
        <div className='shadow-lg overflow-hidden rounded-2xl min-h-[220px]'><Stream controls src={media.uid} poster={media.thumbnail} onPlay={onPlay} onEnded={onEnded} /></div>
    )
}


const Content = (details) => {
  const [showCaption, setShowCaption] = useState(true);
  const [videoEnded, setVideoEnded] = useState(false);
  const hideCaption = () => setShowCaption(false);
  const showCaptionFunc = () => setShowCaption(true);
  const showVideoCaption = () => {
    setVideoEnded(true);
    showCaptionFunc()
  }
  
  return (
    <div className={classNames("mb-4 relative", {
      'overflow-hidden': !showCaption
    })}>
      {
        {
          'image': <Image {...details} />,
          'video': <Video {...details} onPlay={hideCaption} onEnded={showVideoCaption} />
        }[details.featured_media.type] || 
          <>Content appears to be broken!</>
      }
      <div className={classNames('absolute px-2.5 bottom-3.5 w-full', {
        'translate-y-72': !showCaption,
        '-translate-y-7': videoEnded
        })}>
          <div className='flex w-full bg-gradient-to-br from-white/60 rounded-2xl p-4 backdrop-blur-lg shadow-lg'>
            <div className="leading-5 flex-1 pr-4">
              <p className='font-medium'>{details.title}</p>
              <span className='text-[10px] tracking-wider uppercase text-[#e2e0e0]'>{details.featured_media.type}</span>
            </div>

            <span className='bg-white w-8 h-8 rounded-2xl'>
              {
                {
                  'image': <PictureIcon className='w-8 h-8 fill-primary' />,
                  'video': <PlayIcon className='w-8 h-8 fill-primary' />
                }[details.featured_media.type] || <ArrowRightIcon className='w-8 h-8 fill-primary' />
              }
            </span>
          </div>
        </div>
    </div>
  )
}

export default Content