import Logo from '@assets/img/logo.png'
import IndicoLogo from '@assets/img/indico.png'
import Menu from './Menu'
import { useNavigate, useLocation } from 'react-router-dom'
import { useStore } from '@context/store'


const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { serviceName } = useStore();

  return (
    <div className='flex items-center shadow-lg shadow-gray-200 relative z-10 px-4 h-14'>
      <div className="flex justify-between w-full py-2">
        <div className="flex gap-2" onClick={() => navigate("/")}>
          <img src={Logo} alt="Logo" className='h-5 w-auto' />
          {
            {
              indico: <>
                <img src={IndicoLogo} alt="Logo" className='h-4 w-auto' />
              </>,
              infotainment: <span className='font-bold'>Infotainment</span>
            }[serviceName]
          }
        </div>
      </div>
      <div className="">
        {location.pathname !== '/' && 
          <Menu />
        }
      </div>
    </div>
  )
}

export default Navbar