export const WP_REST = 'http://myghvas.com/apanel/index.php/wp-json/';
export const SERVICES = {
  ic: 'indico',
  if: 'infotainment',
  test: 'test'
};
export const SERVICE_SUMMARY = {
  indico: <>Indico provides subscribers access to various forms of engaging headline information on various subject areas that educate and provide self development.</>,

  infotainment: <>Information provides subscribers access to various forms of engaging headline information on various subject areas that educate and provide self development.</>
}