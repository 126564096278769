const querify = query => '?' + Object.keys(query).map(key => key + '=' + query[key]).join("&");

export default function endpoint( action, query ) {
    const endpoint = {
        unsubscribe: 'app.php',
        subscribe: 'app.php',
        check: 'check_sub.php'
    }[action];

    if(!endpoint) {
        return '';
    }

    let extension = endpoint;
    delete query.category;

    const queryStr = querify(query);
    
    return `https://apps.mobivs.com/MAD_API_MTN/${extension}${queryStr}`;
}