import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Hero from '@assets/img/image.png'
import HeroGuy from '@assets/img/image-guy.png'
import endpoint from '@utils/endpoints'
import useQuery from '@hooks/useQuery'
import capitalize from '@utils/capitalize'
import axios from 'axios'
import {ReactComponent as CrossIcon} from '@assets/img/icons/cross.svg'
import { ReactComponent as AlertIcon } from '@assets/img/icons/alert.svg'
import { useSetStore } from '../context/store'

const Subscribe = ({
  num: msisdn,
  service: category,
  keyword,
  onConfirmed
}) => {
  const setStore = useSetStore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const heartBeat = useRef();
  const query = useQuery();
  const navigate = useNavigate();
  const feedback = query.get("result");
  // console.log(keyword)

  const checkSubscription = (num=msisdn) => {
    const url = endpoint('check', {
        num,
        offer_id: keyword.service_id,
    });

    axios.get(url).then(resp => {
        if(!resp.data.status) {
            heartBeat.current = setTimeout(checkSubscription, 1000);
        } else {
            console.log('subscribed');
            // handleSubscription();
            onConfirmed();
        }
    }).catch(() => {
        clearTimeout(heartBeat.current);
    });
  }

  const handleSubscription = () => {
    console.log("🚀 ~ phoneNumber:", phoneNumber)
    setLoading(true);
    let pauseSubscription = false;
    const visitor = msisdn || '233'+phoneNumber.substr(-9);

    let subURL = endpoint('subscribe', {
      msisdn: visitor,
      subscription_id: keyword.service_id,
      channel: 'USSD',
      activity: 'subscribe',
      category
    })

    if (keyword.tag === 'Test') {
      if (keyword.product_id.includes('mtn')) {
        const activity = keyword.product_id.includes('subscribe') ? 'subscribe' : 'charge';
        subURL = `https://apps.mobivs.com/TEST_MTN/app.php?msisdn=${msisdn}&subscription_id=${keyword.service_id}&channel=APP&activity=${activity}`
      }

      if (keyword.product_id === 'cgportal') {
        pauseSubscription = true;
        // console.log("🚀 ~ handleSubscription ~ msisdn:", msisdn);
        const mobileNumberKey = msisdn ? 'msisdn' : 'mobileNumber';
        // console.log("🚀 ~ handleSubscription ~ mobileNumberKey:", mobileNumberKey)
        // console.log("🚀 ~ handleSubscription ~ phoneNumber:", phoneNumber)
        const theNumber = phoneNumber || msisdn;
        const redirectUrl = encodeURIComponent(`http://myghvas.com/mtn-sitcg`);
        window.location.replace(`https://sitcg.mtn.com.gh/Portal?OfferCode=${keyword.service_id}&redirectUrl=${redirectUrl}&${mobileNumberKey}=${theNumber}`);
      }
    }

    if (!(keyword.tag === 'Test' && keyword.product_id === 'cgportal')) {
      if (phoneNumber !== '') {
        pauseSubscription = true;
        setStore(prev => ({
          ...prev,
          user: {
            ...prev.user,
            msisdn: visitor
          }
        }))
        checkSubscription(visitor);
      }
    }

    console.log("🚀 ~ handleSubscription ~ pauseSubscription:", pauseSubscription)
    console.log("🚀 ~ handleSubscription ~ subURL:", subURL)
    if (!pauseSubscription) {
      axios.get(subURL, {headers: {
  'Access-Control-Allow-Origin' : '*',
  'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}})
        .then(resp => {
          const { status, data } = resp;
          if (status === 200) {
            if (keyword.tag === 'Test' && keyword.product_id.includes('mtn')) {
              return false;
            }

            if (keyword.tag === 'Test' && keyword.product_id === 'cgportal') {
              return false;
            }

            checkSubscription();

            return false;
          } else if (status === 500) {
            setLoading(false);
            setError('Something went wrong, please try again later.');
          }

          setLoading(false);
          setError(data.message);
          return false
        })
        .catch((error) => {
          console.log('erorr---->', error.response)
          if (error.response) {
            if (error.response.data.message) {
              setError(error.response.data.message);
            }
          } else {
            setError('Something went wrong, please try again later.')
          }
          setLoading(false);
          clearTimeout(heartBeat)
        })
    }
  }

  useEffect(() => {
    return () => clearTimeout(heartBeat.current);
  }, [])

  return (
    <div
      tabIndex="-1"
      aria-hidden="true"
      className="bg-[#6052EA] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full"
    >
      <div className="relative w-full max-w-2xl h-full md:h-auto">

        <div className="relative pb-6">
          <div className="relative min-h-[300px]">
            <img src={Hero} alt="Subscribe" />
            <div className="absolute top-10">
              <p className="text-[110px] text-[#D7D4FE] font-bold uppercase">{keyword?.name}</p>
            </div>
            <div className="absolute top-0">
              <img src={HeroGuy} alt="Hero" />
            </div>
          </div>

          <div className="px-4">
            <div className="bg-gradient-to-b from-[#6052EA]/30 rounded-2xl p-4 backdrop-blur-sm -mt-10 border border-[#9990F4]/30 shadow-2xl relative">
              <button
                className="w-12 h-12 bg-white rounded-full absolute -top-6 -right-3 shadow-lg shadow-black/25 flex justify-center items-center"
                onClick={() => navigate(-1)}
              >
                <CrossIcon />
              </button>
              <div className="text-white pt-6">
                <h1 className='font-bold text-3xl mb-4'>{keyword.name}</h1>
                <p className='mb-4'>You are currently not subscribed to view this content.</p>

                <p className=''>To get full access for content on {keyword.name}, subscribe below.</p>
              </div>

              <div className='block mt-6 relative'>
                <div className={`absolute group-hover:flex -top-2 -translate-y-full w-full px-2 py-2 bg-red-100 rounded-lg text-red-600 text-sm after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-red-100 shadow-xl ${error === '' && 'hidden'}`}>
                  <div className="flex gap-2">
                    <AlertIcon className='fill-red-700' />

                    {
                      {
                        '3': <>
                          <div className="">Subscribing to <strong>{capitalize(category)} {capitalize(keyword.name)}</strong>
                          <br /> has failed. Please try again.</div>
                        </>,
                        '6': <>
                          <div className="" style={{color: 'rgb(250, 158, 72)'}}>Wifi subscription not allowed
                          <br /> switch to <strong>mobile data</strong> and try again.</div>
                        </>
                      }[feedback]
                    }

                    {error !== '' &&
                      <div className="">Subscribing to <strong>{capitalize(category)} {capitalize(keyword.name)}</strong>
                      <br /> failed. {error}</div>
                    }
                  </div>
                </div>

                {!msisdn &&
                  <div className='mb-4'>
                    <input
                      className='w-full rounded-lg p-3 px-4 text-3xl font-bold text-center'
                      placeholder='Enter your number'
                      value={phoneNumber}
                      onChange={({target: {value}}) => setPhoneNumber(value)}
                    />
                  </div>
                }
                <button
                  className='bg-white rounded-lg w-full text-primary font-bold py-3 text-2xl shadow-xl shadow-slate-[#6052EA] flex justify-center items-center'
                  onClick={handleSubscription}
                  disabled={loading}
                >
                  {loading ?
                    <div
                      role="status" aria-hidden="true"
                      className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-7 w-7"
                    ></div>
                  :
                    <><span className='uppercase'>Subscribe</span>&nbsp; @ &nbsp;<span className=''>{keyword.price}p</span></>
                  }
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Subscribe
